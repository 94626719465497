import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HelpModal from './components/HelpModal/';
import QuestionaryModal from './components/QuestionaryModal/';
import ExplanationModal from './components/ExplanationModal/';
import ControlArea from './components/ControlArea';
import StepsArea from './components/StepsArea';
import CanvasView from './components/CanvasView';
import { steps as initialSteps, tools } from './configs';

import './style.scss';
import { Api } from 'utils/connectors';
import { generateViewerData } from './utils';
import Loading from 'shared/components/Loading';
import { onCourseUpdate } from '../Courses/actions';
import HeatmapModal from './components/HeatmapModal';
import { removeFromStore } from 'utils/storeHelpers';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';

const Viewer = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const courses = useSelector(store => store.courses);
  const viewerOptions = useSelector(state => state.viewerOptions);
  const dispatch = useDispatch();
  const { courseId, lessonId } = match.params;

  const [activeTool, setActiveTool] = useState({});
  const [fullScreen, setFullScreen] = useState(false);

  const [fetch, setFetch] = useState();
  const [course, setCourse] = useState();
  const [activeCase, setActiveCase] = useState(0);
  const [activeStep, setActiveStep] = useState(0);

  const [openHelpModal, setOpenHelpModal] = useState(false);

  const reduxCourse = courses.find(item => item.id === Number(courseId));
  const reduxLesson =
    reduxCourse && reduxCourse.lessons.find(lesson => lesson.id === Number(lessonId));

  const activeCaseId = reduxLesson?.caseIds?.[activeCase]?.caseId || 0;
  const activeCaseEpisodes =
    reduxLesson?.episodes?.length && reduxLesson.episodes.filter(ep => ep.caseId === activeCaseId);
  const sortedActiveCaseEpisodes =
    activeCaseEpisodes &&
    [...activeCaseEpisodes].sort((ep1, ep2) => {
      return ep1.orderNum - ep2.orderNum;
    });

  const fullScreenChange = () => {
    document.addEventListener('fullscreenchange', e => {
      setFullScreen(!!document.fullscreenElement);
    });
  };

  const handelActiveToolChange = tool => {
    setActiveTool(tool);
    if (tool && tool.type === 'findingReset') resetFinding();
  };

  const getCases = async () => {
    if (!reduxLesson || !reduxLesson.caseIds || !reduxLesson.caseIds.length) return;
    const { caseId, caseUniqueId } = reduxLesson.caseIds[activeCase];
    const endpoint = `/cases/getcasebyid/${caseUniqueId || caseId}?lut=1&dicomType=all`;
    const { data } = await Api.get(endpoint);
    const cases = generateViewerData(data.data, reduxLesson);
    setCourse({
      title: reduxLesson.name,
      lesson: reduxLesson,
      cases,
    });
    // Update first step
    if (!cases[0].completed_steps.includes(0)) {
      updateDicomStepData(0);
    }
  };

  const onCaseChange = async value => {
    if (!course.cases[value]) {
      setFetch(true);
      const { caseId, caseUniqueId } = reduxLesson.caseIds[value];
      const endpoint = `/cases/getcasebyid/${caseUniqueId || caseId}?lut=1&dicomType=all`;
      const { data } = await Api.get(endpoint);
      const caseData = generateViewerData(data.data, reduxLesson);
      const cases = [...course.cases, ...caseData];
      if (!cases[value].completed_steps.includes(0)) {
        updateDicomStepData(0, caseId);
        cases[value].completed_steps.push(0);
      }
      await setCourse({ ...course, cases });
      await setActiveCase(value);
      setFetch(false);
    } else {
      setActiveCase(value);
    }
  };

  const updateLessonsEpisode = index => {
    const tempCourse = { ...reduxCourse };
    const lessonIndex = tempCourse.lessons.findIndex(item => item.id === Number(lessonId));
    const tempLesson = tempCourse.lessons[lessonIndex];
    const tempEpisode = tempLesson.episodes.find(
      ep => ep.id === sortedActiveCaseEpisodes[index].id,
    );
    if (tempEpisode) {
      tempEpisode.status = 1;
      dispatch(onCourseUpdate(tempCourse));
    }
  };

  const updateDicomStepData = async (step, id) => {
    const caseId = id || reduxLesson.caseIds[activeCase].caseId;
    const body = {
      lessonId,
      completed: 1,
      step: step + 1,
      caseId: Number(caseId),
      courseId: reduxCourse?.id,
    };
    await Api.post('/courses/updateuserlesson', body);
    updateLessonsEpisode(step);
  };

  const updateAndAddCompletedStep = async step => {
    await updateDicomStepData(step);
    completedSteps.push(step);
  };

  const stepChangeHandler = async step => {
    const isModal = step === 9 || step === 10 || step === 11;
    if (isModal) setActiveTool({});
    if (!completedSteps.includes(step) && !isModal) {
      await updateAndAddCompletedStep(step);
    }
    setActiveStep(step);
  };

  const updateFinding = async body => {
    await Api.post('/dicom/savedicomvectordata', body);
  };

  const resetFinding = async () => {
    try {
      const body = {
        userLessonId: reduxLesson.userLessonId,
        caseId: Number(reduxLesson.caseIds[activeCase].caseId),
      };
      await Api.delete('/dicom/dicomvectordata', {
        data: body,
      });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const lessonStartLog = () => {
    Api.post('/courses/lesson/start', {
      courseId: reduxCourse.id,
      lessonId: reduxLesson.id,
    });
  };

  useEffect(() => {
    fullScreenChange();
    getCases();
    removeFromStore('viewerToolTip');
    lessonStartLog();
    //eslint-disable-next-line
  }, []);

  if (!course || fetch) return <Loading classView='min-vh-100' />;

  let steps = [...initialSteps];

  const selectedCase = reduxLesson.caseIds[activeCase];
  if (!!reduxCourse.isOpened || !selectedCase.hasHeatmap) {
    // remove questionary and heatmaps
    const excludeNames = reduxCourse.isOpened
      ? ['question', 'heatmap']
      : !selectedCase.hasHeatmap
      ? ['heatmap']
      : [];
    steps = steps.filter(item => !excludeNames.includes(item.name));
  }

  const isModal = steps[activeStep].type === 'modal';
  const caseViews = course.cases[activeCase].views;
  const completedSteps = course.cases[activeCase].completed_steps;
  const stepViews = steps[activeStep].views;
  const stepViewsData = Object.values(stepViews);
  const viewsCount = Object.values(stepViews).length;

  return (
    <div className='viewer d-flex flex-column flex-fill'>
      <ControlArea
        course={course}
        fullScreen={fullScreen}
        activeTool={activeTool}
        setActiveTool={handelActiveToolChange}
        history={history}
        viewerOptions={viewerOptions}
        tools={tools}
      />
      <div
        className={`viewer-area d-flex ${fullScreen ? 'fullscreen' : ''}`}
        key={`${activeCase}_${activeStep}`}
      >
        {!isModal &&
          stepViewsData.map((item, index) => (
            <CanvasView
              key={item.key + index}
              dataTomo={caseViews[`${item.key}_TOMO`]}
              data2D={caseViews[`${item.key}_2D`]}
              data3DQ={caseViews[`${item.key}_3DQ`]}
              step={item}
              activeTool={activeTool}
              setActiveTool={handelActiveToolChange}
              fullScreen={fullScreen}
              viewsCount={viewsCount}
              updateFinding={updateFinding}
              viewerOptions={viewerOptions}
            />
          ))}
        {isModal && (
          <>
            {steps[activeStep].name === 'question' && (
              <QuestionaryModal
                episodes={sortedActiveCaseEpisodes}
                lessonId={lessonId}
                changeStep={stepChangeHandler}
                completedSteps={completedSteps}
                step={steps[activeStep]}
                isOpened={reduxCourse.isOpened}
                caseId={reduxLesson.caseIds[activeCase].caseId}
                updateLessonsEpisode={updateLessonsEpisode}
                course={reduxCourse}
              />
            )}
            {steps[activeStep].name === 'explanator' && (
              <ExplanationModal
                isDisabled={completedSteps.length < steps[activeStep].disabled_no_active}
                updateStep={updateAndAddCompletedStep}
                caseData={reduxLesson.caseIds[activeCase]}
                lessonId={lessonId}
                caseId={reduxLesson.caseIds[activeCase].caseId}
                caseViews={caseViews}
              />
            )}
            {steps[activeStep].name === 'heatmap' && (
              <HeatmapModal
                isDisabled={completedSteps.length < steps[activeStep].disabled_no_active}
                caseId={reduxLesson.caseIds[activeCase].caseId}
                lessonId={lessonId}
                isOpened={reduxCourse.isOpened}
                userLessonId={reduxLesson.userLessonId}
                updateStep={updateAndAddCompletedStep}
              />
            )}
          </>
        )}
      </div>
      <StepsArea
        reduxCourse={reduxCourse}
        reduxLesson={reduxLesson}
        steps={steps}
        completedSteps={completedSteps}
        activeCase={activeCase}
        changeActiveCase={onCaseChange}
        activeStep={activeStep}
        changeActiveStep={stepChangeHandler}
        setActiveStep={setActiveStep}
        onHelpModalOpen={setOpenHelpModal}
        history={history}
      />
      {openHelpModal && <HelpModal onModalClose={() => setOpenHelpModal(false)} />}
    </div>
  );
};

export default Viewer;
