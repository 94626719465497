import React from 'react';
import {
  IconPlayFill,
  IconLock,
  IconVideos,
  CircleFillIcon,
  NumberQuestionSignIcon,
} from 'shared/components/Icons';
import { bindDuration, getEventUrl } from 'utils/appHelpers';
import { hasAccess } from 'utils/permissionHelper';
import { DEFAULT_IMG_URLS, LESSON_STATUSES } from 'configs/constants';

const LessonsListSection = ({ lessons, activeLesson, history, courseId }) => {
  const path = hasAccess('event_user') ? getEventUrl() : '';

  const lessonClickHandle = (lesson, e) => {
    if (lesson.status === LESSON_STATUSES.notStarted) {
      e.preventDefault();
      return;
    }

    const isPLayButton = e.target?.ariaLabel === 'lessonPlay';

    if (!isPLayButton) {
      history.push(`${path}/courses/${courseId}/${lesson.type}/${lesson.id}`);
      return;
    }

    const lessonPath = `${path}/courses/${courseId}/${lesson.type}/${lesson.id}`;

    if (lesson.type === 'cases') {
      history.push(`${lessonPath}/play`);
    } else if (lesson.type === 'video') {
      history.push(`${lessonPath}/auto`);
    } else {
      history.push(`${lessonPath}`);
    }

    window.scrollTo(0, 0);
  };

  return (
    <div className='courses-list gap-4 d-flex flex-column'>
      {lessons &&
        lessons
          .sort((l1, l2) => l1.lessonOrder - l2.lessonOrder)
          .map(lesson => {
            const active = lesson.id === activeLesson.id;
            const isLessonUnlocked =
              lesson.status === LESSON_STATUSES.inProgress ||
              lesson.status === LESSON_STATUSES.completed;
            const isFeedback = lesson.type === 'feedback';
            const isQuiz = lesson.type === 'quiz';
            const UnlockedIcon = active ? IconPlayFill : CircleFillIcon;
            const Icon = isLessonUnlocked ? UnlockedIcon : IconLock;
            return (
              <div
                key={lesson.id}
                onClick={lessonClickHandle.bind(null, lesson)}
                role='button'
                tabIndex='-1'
                className={`list-item d-flex pointer ${
                  active ? 'active' : ''
                } ${!isLessonUnlocked && 'not-allowed'} justify-content-between ${(isFeedback ||
                  isQuiz) &&
                  'bg-lightgray'}`}
              >
                <div className='d-flex align-items-center'>
                  <div aria-label='lessonPlay'>
                    <Icon ariaLabel='lessonPlay' />
                  </div>
                  <img
                    className='mx-3'
                    src={lesson?.image || DEFAULT_IMG_URLS.lesson}
                    alt={lesson.name}
                  />
                  <div className='d-flex flex-column'>
                    <p className='name'>{lesson.name}</p>
                    <p className='description'>{lesson.description}</p>
                  </div>
                </div>
                {!isFeedback && (
                  <div
                    className={`time-part ${isQuiz &&
                      'quiz-time'} d-flex flex-column justify-content-around align-items-end`}
                  >
                    {lesson.episodes && (
                      <span>
                        {lesson.episodes.length}{' '}
                        {isQuiz ? <NumberQuestionSignIcon /> : <IconVideos />}
                      </span>
                    )}
                    <p>{bindDuration(isQuiz ? lesson.duration * 60 : lesson.duration)}</p>
                  </div>
                )}
              </div>
            );
          })}
    </div>
  );
};

export default LessonsListSection;
